import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/frmLogin.vue'),
  },
  {
    path: '/rastreamento',
    name: 'Rastreamento',
    component: () => import('../views/frmRastreamento.vue'),
    props: (route) => ({ rastreio: route.query.rastreio }),
  },
  {
    path: '/',
    name: 'Principal',
    component: () => import('../views/frmPrincipal.vue'),
    children: [
      // Produtos:
      {
        path: '/cadastro-produtos',
        name: 'CadastroProdutos',
        component: () => import('../views/produtos/listar_produtos/ListarProdutos.vue'),
      },
      {
        path: '/importar-produtos',
        name: 'ImportarProdutos',
        component: () => import('../views/produtos/importar/frmImportProduto.vue'),
      },
      {
        path: '/importar-agregados',
        name: 'ImportarAgregados',
        component: () => import('../views/produtos/importar/frmImportAgregados.vue'),
      },
      {
        path: '/importar-volumes-extras',
        name: 'ImportarVolumesExtras',
        component: () => import('../views/produtos/importar/frmImportVolumesExtras.vue'),
      },
      {
        path: '/produtos/inserir',
        name: 'InserirProduto',
        component: () => import('../views/produtos/frmInserirProduto.vue'),
      },
      // {
      //   path: '/produtos/listar',
      //   name: 'ListarProdutos',
      //   component: () => import('../views/produtos/listar_produtos/ListarProdutos.vue'),
      // },
      {
        path: '/produtos/editar',
        name: 'EditarProduto',
        component: () => import('../views/produtos/frmEditarProduto.vue'),
      },
      //
      {
        path: '/nova-cotacao-frete',
        name: 'NovaCotacaoFrete',
        component: () => import('../views/frmNovaCotacaoFrete.vue'),
      },
      {
        path: '/cadastro-clientes',
        name: 'CadastroClientes',
        component: () => import('../views/frmCadastroClientes.vue'),
      },
      {
        path: '/atrasados',
        name: 'Atrasados',
        component: () => import('../views//relatorios/frmAtrasados.vue'),
      },
      {
        path: '/chamados',
        name: 'Chamados',
        component: () => import('../views/frmChamados.vue'),
      },
      {
        path: '/devolvidos',
        name: 'Devolvidos',
        component: () => import('../views/frmDevolvidos.vue'),
      },
      {
        path: '/entregues',
        name: 'Entregues',
        component: () => import('../views/relatorios/frmEntregues.vue'),
      },
      {
        path: '/centros-de-distribuicao',
        name: 'Centros de Distribuicao',
        component: () => import('../views/cds/frmCadastroCD.vue'),
      },
      {
        path: '/canal-venda',
        name: 'Canal de Vendas',
        component: () => import('../views/cds/frmCanalVendas.vue'),
      },
      {
        path: '/modais',
        name: 'Modais',
        component: () => import('../views/frmModais.vue'),
      },
      {
        path: '/indicadores',
        name: 'Indicadores',
        component: () => import('../views/frmIndicadores.vue'),
      },
      // {
      //   path: '/indicadores-transp',
      //   name: 'Indicadores-transp',
      //   component: () => import('../views/frmPorTransportadora.vue'),
      // },
      {
        path: '/indicadores-transp',
        name: 'Indicadores-transp',
        component: () => import('../views/frmNovoPorTransportadora.vue'),
      },
      {
        path: '/indicadores-integracao',
        name: 'Indicadores-integracao',
        component: () => import('../views/frmIndicadoresIntegracao.vue'),
      },
      {
        path: '/empresas',
        name: 'Empresas',
        component: () => import('../views/frmEmpresas.vue'),
      },
      {
        path: '/empresas-antigo',
        name: 'Empresas Antigo',
        component: () => import('../views/frmEmpresasAntigo.vue'),
      },
      {
        path: '/empresas-tab',
        name: 'EmpresasTab',
        component: () => import('../views/frmEmpresasTab.vue'),
      },
      {
        path: '/exportacao-reclamacao-correios',
        name: 'ExportacaoReclamacaoCorreios',
        component: () => import('../views/frmExportacaoReclamacaoCorreios.vue'),
      },
      {
        path: '/analise-reclamacao',
        name: 'AnaliseReclamacao',
        component: () => import('../views/frmAnaliseReclamacao.vue'),
      },
      {
        path: '/importacao-objetos',
        name: 'ImportacaoObjetos',
        component: () => import('../views/frmImportacaoObjetos.vue'),
      },
      {
        path: '/importacao-retorno-correios',
        name: 'ImportacaoRetornoCorreios',
        component: () => import('../views/frmImportacaoRetornoCorreios.vue'),
      },
      {
        path: '/importacao-retorno-correios-new',
        name: 'ImportacaoRetornoCorreiosNew',
        component: () => import('../views/frmImportacaoRetornoCorreiosNew.vue'),
      },
      {
        path: '/objetos',
        name: 'Objetos',
        component: () => import('../views/frmObjetos.vue'),
      },
      {
        path: '/objetos-novo',
        name: 'ObjetosNovo',
        component: () => import('../views/frmObjetosEmpresaCinco.vue'),
      },
      {
        path: '/pre-venda',
        name: 'PreVenda',
        component: () => import('../views/frmPreVenda.vue'),
      },
      {
        path: '/nao-encontrados',
        name: 'Não encontrados',
        component: () => import('../views/relatorios/frmNaoEncontrados.vue'),
      },
      {
        path: '/relatorios/andamento',
        name: 'Em Andamento',
        component: () => import('../views/relatorios/frmPendentes.vue'),
      },
      {
        path: '/relatorios/aguardando-envio',
        name: 'Aguardando Envio',
        component: () => import('../views/relatorios/frmAguardandoEnvio.vue'),
      },
      {
        path: '/sem-movimentacao',
        name: 'Sem Movimentacao',
        component: () => import('../views/relatorios/frmSemMovimentacao.vue'),
      },
      {
        path: '/relatorio-mensal',
        name: 'Relatório Mensal',
        component: () => import('../views/relatorios/frmRelatorioMensal.vue'),
      },
      {
        path: '/log-integracao',
        name: 'LogIntegracao',
        component: () => import('../views/frmLogIntegracao.vue'),
      },
      {
        path: '/status-rastreios',
        name: 'StatusRastreios',
        component: () => import('../views/frmStatusRastreios.vue'),
      },
      {
        path: '/status-rastreios-proceda',
        name: 'StatusRastreiosProceda',
        component: () => import('../views/frmStatusRastreiosProceda.vue'),
      },
      {
        path: '/transportadoras',
        name: 'Transportadoras',
        component: () => import('../views/frmTransportadoras.vue'),
      },
      {
        path: '/tratativas-pendentes',
        name: 'TratativasPendentes',
        component: () => import('../views/frmTratativasPendentes.vue'),
      },
      {
        path: '/notas-nao-localizadas',
        name: 'NotasNaoLocalizadas',
        component: () => import('../views/frmNotasNaoLocalizadas.vue'),
      },
      {
        path: '/chaves-integracoes',
        name: 'ChavesIntegracao',
        component: () => import('../views/frmConfigTokenIntegracao.vue'),
      },
      {
        path: '/transportadora1',
        name: 'Transportadora 1',
        component: () => import('../views/frmTr01.vue'),
      },
      {
        path: '/chaves-cotacao',
        name: 'ChavesCotacao',
        component: () => import('../views/frmConfigTokenCotacao.vue'),
      },
      {
        path: '/config-transportadoras',
        name: 'Configuracao de Transportadoras',
        component: () => import('../views/frmConfigTransportadora.vue'),
      },
      {
        path: '/usuarios',
        name: 'Usuarios',
        component: () => import('../views/frmUsuarios.vue'),
      },
      {
        path: '/upload-nfe',
        name: 'UploadNfe',
        component: () => import('../views/frmUploadNfe.vue'),
      },
      {
        path: '/status-crons',
        name: 'StatusCron',
        component: () => import('../views/frmStatusCron.vue'),
      },
      {
        path: '/status-notifications',
        name: 'StatusNotificacoes',
        component: () => import('../views/frmStatusNotificacoes.vue'),
      },
      {
        path: '/reclamacoes-objetos',
        name: 'Reclamacoes',
        component: () => import('../views/relatorios/frmReclamacoes.vue'),
      },
      {
        path: '/importacao-proceda',
        name: 'ImportacaoProceda',
        component: () => import('../views/frmImportacaoProceda.vue'),
      },
      {
        path: '/importacao-pedidos',
        name: 'ImportacaoPedidos',
        component: () => import('../views/frmImportacaoPedidos.vue'),
      },
      {
        path: '/relatorios/alerta',
        name: 'AlertaObjetos',
        component: () => import('../views/relatorios/alerta/RelatorioAlerta.vue'),
      },
      {
        path: '/slacorreios',
        name: 'AtrasadosSLACorreios',
        component: () => import('../views/frmAtrasadosSla.vue'),
      },
      {
        path: '/movimento-pos-entrega',
        name: 'Movimento Pos Entrega',
        component: () => import('../views/relatorios/frmMovimentoPosEntrega.vue'),
      },
    ],
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
